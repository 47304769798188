
import { Input, Textarea, CheckboxGroup, Checkbox, Spinner, Button, Table, TableHeader, TableColumn, TableCell, TableBody, TableRow, Select, SelectItem, Tab } from '@nextui-org/react';
import RouterLink from '../RouterLink';
import { useQuery, useMutation } from '@tanstack/react-query';
import { getUserDeliveries, getUserKeys, deleteDelivery, sendDelivery } from '../../endpoints/mailgun';

const MailgunDeliveryList = (props) => {

    const { user, keys, deliveries, deliveryRefetch } = props;


    const { data: usersData } = useQuery({
        queryKey: [ 'getUserKeys', user ],
        queryFn: getUserKeys,
        placeholderData: undefined,
    });

    const getKey = acct => {
        if (keys) {
            const key = keys.find(key => key._id === acct);
            return key ? key.name : 'Unknown';
        }
        return 'Unknown';
    }

    const sendNow = useMutation({
        mutationFn: sendDelivery
    });

    const revoke = useMutation({
        mutationFn: deleteDelivery,
        onSuccess: () => {
            revoke.reset();
            deliveryRefetch();
        }
    });

    const days = [ 'Su', 'M', 'T', 'W', 'Th', 'F', 'Sa' ];
    const times = Array.from(Array(24).keys()).map(v => {
        let hour = v > 12 ? v - 12 : v;
        let ampm = v >= 12 ? 'pm' : 'am';
        if (hour == 0 && ampm == 'am') { v = 12; }
        return `${hour}:00${ampm}`;
    });

    return (
        <div className="flex flex-col gap-4 pb-5">
            <h2 className="text-lg">Your MailGun Deliveries</h2>
            <Table>
                <TableHeader>
                    <TableColumn>Account</TableColumn>
                    <TableColumn>Delivery Time</TableColumn>
                    <TableColumn>Subject</TableColumn>
                    <TableColumn>Message</TableColumn> 
                    <TableColumn>Recipients</TableColumn> 
                    <TableColumn>Email Filters</TableColumn> 
                    <TableColumn>Live only?</TableColumn> 
                    <TableColumn>Actions</TableColumn>
                </TableHeader>
                <TableBody>
                    {
                        deliveries && usersData && deliveries.map(delivery => {
                            return (
                                <TableRow key={delivery._id}>
                                    <TableCell>{getKey(delivery.account)}</TableCell>
                                    <TableCell>{`${days[delivery.day]} ${times[delivery.time]}`}</TableCell>
                                    <TableCell>{delivery.subject}</TableCell>
                                    <TableCell>{delivery.message}</TableCell>
                                    <TableCell>{delivery.recipients}</TableCell>
                                    <TableCell>{delivery.emailFilters ?? ""}</TableCell>
                                    <TableCell>{delivery.liveOnly ? 'Yes' : ''}</TableCell>
                                    <TableCell>
                                        <div className="flex justify-start gap-2 py-2">
                                            <Button color="primary" onClick={() => {
                                                console.log(delivery);
                                                sendNow.mutate({ id: delivery._id });
                                            }}>Send Now</Button>
                                            <Button color="danger" onClick={() => {
                                                revoke.mutate(delivery._id);
                                            }}>Delete</Button>
                                        </div>  
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </div>
    );

}

export default MailgunDeliveryList;
