
import axios from 'axios';

const getDomains = async () => {
    const config = {
        withCredentials: true,
        headers: { "Content-Type": "application/json" }
    };
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/mailgun/domain/list/`, config);
    return response.data;
}

const getRoutes = async () => {
    const config = {
        withCredentials: true,
        headers: { "Content-Type": "application/json" }
    };
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/mailgun/route/list/`, config);
    return response.data;
}

const getUserRoutes = async ({ queryKey }) => {
    const [ _, user ] = queryKey;
    const config = {
        withCredentials: true,
        headers: { "Content-Type": "application/json" }
    };
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/mailgun/route/list/${user}`, config);
    return response.data;

}

const createRoute = async (routeData) => {
    const config = {
        withCredentials: true,
        headers: { "Content-Type": "application/json" }
    };
    const body = { ...routeData };
    const response = await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/mailgun/route`, body, config);
    return response.data;
}

const getRoute = async ({ queryKey }) => {
    const [ _, id, key ] = queryKey;
    const config = {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
        params: { key }
    };
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/mailgun/route/${id}`, config);
    return response.data;
}

const updateRoute = async (routeData) => { 
    const config = {
        withCredentials: true,
        headers: { "Content-Type": "application/json" }
    };
    const body = { ...routeData };
    const response = await axios.put(`${process.env.REACT_APP_ENDPOINT_URL}/mailgun/routes/${routeData.id}`, body, config);
    return response.data;
}

const addKey = async (keyData) => {
    const config = {
        withCredentials: true,
        headers: { "Content-Type": "application/json" }
    };
    const body = { ...keyData };
    const response = await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/mailgun/keys`, body, config);
    return response.data;
}

const updateMailgunKey = async (data) => {
    const config = {
        withCredentials: true,
        headers: { "Content-Type": "application/json" }
    };
    const body = data.record;
    const response = await axios.put(`${process.env.REACT_APP_ENDPOINT_URL}/mailgun/key/${data.keyId}`, body);
    return response.data;
}

const getMailgunKey = async ({ queryKey }) => {
    const[ _, keyId ] = queryKey;
    const config = {
        withCredentials: true,
        headers: { "Content-Type": "application/json" }
    };
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/mailgun/key/${keyId}`, config);
    return response.data;
}

const getUserKeys = async ({ queryKey }) => {
    const [ _, user ] = queryKey;
    const config = {
        withCredentials: true,
        headers: { "Content-Type": "application/json" }
    };
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/mailgun/keys/${user}`, config);
    return response.data;
}

const createDelivery = async (routeData) => {
    const config = {
        withCredentials: true,
        headers: { "Content-Type": "application/json" }
    };
    const body = { ...routeData }
    const response = await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/mailgun/delivery`, body, config);
    return response.data;
}

const deleteDelivery = async (data) => {
    const config = {
        withCredentials: true,
        headers: { "Content-Type": "application/json" }
    };
    const response = await axios.delete(`${process.env.REACT_APP_ENDPOINT_URL}/mailgun/delivery/${data}`, config);
    return response.data;
} 

const sendDelivery = async (data) => {
    const config = {
        withCredentials: true,
        headers: { "Content-Type": "application/json" }
    };
    const response = await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/mailgun/delivery/send/${data.id}`, data, config);
    return response.data;
}

const getUserDeliveries = async({ queryKey }) => {
    const [ _, user ] = queryKey;
    const config = {
        withCredentials: true,
        headers: { "Content-Type": "application/json" }
    };
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/mailgun/delivery/list/${user}`, config);
    return response.data;
} 

const sendLiveSiteEmail = async ({ key, user }) => {
    const config = {
        withCredentials: true,
        headers: { "Content-Type": "application/json" }
    };
    const body = { key, user };
    const response = await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/mailgun/live-routes-list`, body, config);
    return response.data;
}

export {
    getDomains,
    getRoutes, getUserRoutes,
    createRoute, getRoute, updateRoute,
    addKey, getMailgunKey, updateMailgunKey, getUserKeys,
    createDelivery, deleteDelivery, getUserDeliveries, sendDelivery,
    sendLiveSiteEmail
};
