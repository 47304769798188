
import { Button, Textarea, Input, Table, Select, SelectItem, TableBody, TableHeader, TableColumn, TableCell, TableRow, Link, CheckboxGroup, Checkbox, Spinner } from '@nextui-org/react';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { queryReviews } from '../endpoints/googlebusinessprofile.reviews';
import { listLocations } from '../endpoints/googlebusinessprofile.locations';
import { getUser } from '../endpoints/auth';
import { listAccounts } from '../endpoints/googlebusinessprofile.accounts';

const ReviewQueryForm = (props) => {

    const { query, setQuery, queryFn } = props;

    const { data: accounts } = useQuery({
        queryKey: ['accounts'],
        queryFn: listAccounts,
        onSuccess: (data) => {
            // Query the locations for the selected account
        }
    });

    const { data: locations } = useQuery({
        queryKey: ['locations'],
        queryFn: listLocations,
        enabled: false
    })

    return (
        <div className="flex flex-col gap-4"> 
            <Input label="Author" value={query.author} onChange={e => {
                setQuery({ ...query, author: e.target.value })
            }}></Input>
            <Textarea label="Comment" value={query.search} onChange={e => {
                setQuery({ ...query, search: e.target.value })
            }}></Textarea>
            <div className="flex flex-row gap-4">
                <Select label="Account" selectedKeys={query.account !== undefined ? [ query.account ] : [ ]}>
                    <SelectItem key='any'>Any</SelectItem>
                    {
                        accounts && accounts[0].accounts.map((account, idx) => {
                            return (
                                <SelectItem key={`${account.name}`} value={`${account._id}`}>{account.accountName} ({account.name.split('/')[1]})</SelectItem>
                            )
                        })
                    }
                </Select>
                <Select label="Location" disabled> </Select>
            </div>
            <CheckboxGroup orientation="horizontal" label="Rating" value={query.rating} onValueChange={e => {
                let newSelected = e;

                if (newSelected.length === 0 || newSelected.at(-1) === 'any') {
                    newSelected = [ 'any' ];
                } else if (newSelected.length > 1 && newSelected.includes('any')) {
                    newSelected = newSelected.filter(v => v !== 'any');
                } else if (newSelected.length === 5 && !newSelected.includes('any')) {
                    newSelected = [ 'any' ];
                }

                setQuery({ ...query, rating: newSelected })
            }}>
                <Checkbox value="any">Any</Checkbox>
                <Checkbox value="1">1</Checkbox>
                <Checkbox value="2">2</Checkbox>
                <Checkbox value="3">3</Checkbox>
                <Checkbox value="4">4</Checkbox>
                <Checkbox value="5">5</Checkbox>
            </CheckboxGroup>
            <div><Button color='primary' onClick={() => {
                queryFn();
            }}>Search</Button></div>
            <pre>{JSON.stringify(query, null, 2)}</pre>
        </div>
    )

}

const ReviewQueryResults = (props) => {

    const { data, query, fetchStatus } = props;
    const { search, author } = query;

    const displayWithHighlight = (text, search) => {
        const regex = new RegExp(search, 'gi');
        return text.replace(regex, (match) => `<span class="bg-yellow-200">${match}</span>`);
    }

    const renderResults = () => {
        
        if (data === undefined || data.length === 0) { return null; }

        return (
            <Table isStriped aria-label="Query results table">
                    <TableHeader>
                        <TableColumn>Location</TableColumn>
                        <TableColumn>Comment</TableColumn>
                        <TableColumn>Rating</TableColumn>
                        <TableColumn>Author</TableColumn>
                        <TableColumn>Excluded</TableColumn>
                    </TableHeader>
                    <TableBody>
                        
                        {data.map((review, idx) => {
                            return (
                                <TableRow key={`queryreview-${idx}`}>
                                    <TableCell>
                                        {
                                            review.location.accounts.length !==0 ? 
                                            <Link href={`/dashboard/accounts/${review.location.accounts[0]._id}/locations/${review.location._id}`}>{review.location.title}</Link>
                                            : <p>{review.location.title}</p>
                                        }
                                    </TableCell>
                                    <TableCell>{review.comment ? 
                                        <div dangerouslySetInnerHTML={{ __html: displayWithHighlight(review.comment, search) }}></div>
                                        : null}
                                    </TableCell>
                                    <TableCell>{review.starRating}</TableCell>
                                    <TableCell>{review.reviewer.displayName ? 
                                    <div dangerouslySetInnerHTML={{ __html: displayWithHighlight(review.reviewer.displayName, author) }}></div>
                                    : null}</TableCell>
                                    <TableCell><Checkbox color="danger"></Checkbox></TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            );
    }

    return (
        <>
        <pre>{fetchStatus}</pre>

        {
            data === undefined && fetchStatus !== 'idle' && <Spinner />
        }
        {
            data && <div className="flex flex-col gap-4">
                <h2 className="text-lg">Review Query Results</h2>
                <p>Found {data.length} result{data.length === 1 ? '' : 's'} matching the query.</p>
                {renderResults()}
            </div>
        }
        </>
    );
}

const ReviewQuery = () => {

    const [ query, setQuery ] = useState({
        search: '',
        author: '',
        rating: [ 'any' ],
        account: 'any',
        user: undefined
    });

    const { data: user } = useQuery({
        queryKey: ['user'], 
        queryFn: getUser, 
        onSuccess: (data) => {
            setQuery({
                ...query,
                user: data.data._id
            })
        }
    })



    const { data, refetch, fetchStatus } = useQuery({
        queryKey: [ 'reviewsQuery', query ],
        queryFn: queryReviews,
        placeholderData: undefined,
        enabled: false
    });

    return (
        <>
        <h1 className="text-xl">Review Query</h1>
        <ReviewQueryForm query={query} setQuery={setQuery} queryFn={refetch} />
        <ReviewQueryResults data={data} query={query} fetchStatus={fetchStatus} />
        </>
    )

}

export default ReviewQuery;
