
import MailgunDelivery from '../../components/mailgun/MailgunDelivery'

const MailgunDeliveryPage = () => {
    return (
        <section>
            <div className="container flex mx-auto">
                <div className="w-full px-4">
                    <main>
                    <MailgunDelivery />
                    </main>
                </div>
            </div>
        </section>
    );
}

export default MailgunDelivery;
