
import { Input, Textarea, CheckboxGroup, Checkbox, Spinner, Button, Table, TableHeader, TableColumn, TableCell, TableBody, TableRow, Select, SelectItem, Tab } from '@nextui-org/react';
import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { getUserRoutes, getUserKeys, sendLiveSiteEmail, createDelivery, getUserDeliveries } from '../../endpoints/mailgun';
import MailgunAddNewDelivery from './MailgunAddNewDelivery';
import MailgunDeliveryList from './MailgunDeliveryList';
import RouterLink from '../RouterLink';

const MailgunDelivery = props => {

    const { user } = props; 

    const [ emailData, setData ] = useState([]);
    const [ account, setAccount ] = useState(undefined);

    const { data: keys } = useQuery({
        queryKey: [ 'mailgunKeys', user ],
        queryFn: getUserKeys,
        placeholderData: undefined
    });

    const { data: deliveries, refetch: deliveryRefetch } = useQuery({
        queryKey: [ 'mailgunDeliveries', user ],
        queryFn: getUserDeliveries,
        placeholderData: undefined
    });

    const sendEmail = (data) => {
        const body = {
            key: account,
            user
        };
        sendLiveSiteEmail(body);
    }

    const postRoute = useMutation(sendEmail, {
        mutationFn: sendEmail,
    });

    return (
        <>
        <MailgunDeliveryList keys={keys} user={user} deliveries={deliveries} deliveryRefetch={deliveryRefetch} />
        <MailgunAddNewDelivery keys={keys} user={user} deliveryRefetch={deliveryRefetch} />
        </>
    )
};

export default MailgunDelivery;
