
import { Input, Textarea, CheckboxGroup, Checkbox, Spinner, Button, Table, TableHeader, TableColumn, TableCell, TableBody, TableRow, Select, SelectItem, Tab } from '@nextui-org/react';
import React, { useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { getUsers } from '../../endpoints/users';
import { createDelivery } from '../../endpoints/mailgun';

const MailgunAddNewDelivery = (props) => {

    const { keys, user: currentUser, deliveryRefetch } = props

    const [ formData, setFormData ] = useState({
        account: undefined,
        day: '',
        time: '',
        subject: '',
        message: '',
        emailFilters: '',
        recipients: '',
        liveOnly: false,
        owners: [],
        managers: []
    });

    const days = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];
    const times = Array.from(Array(24).keys()).map((v, idx) => {
        let hour = v > 12 ? v - 12 : v;
        let ampm = v >= 12 ? 'pm' : 'am';
        if (idx === 0) { hour = 12; }
        return `${hour}:00 ${ampm}`;
    });

    const { data: usersData } = useQuery({
        queryKey: [ 'mailgunAddKeyUsers' ],
        queryFn: getUsers,
        placeholderData: undefined,
    });

    const handleOwnershipChange = (field, e) => {
        const newFormData = {
            ...formData,
            [field]: e
        };

        let newUser = newFormData[field].filter(v => !formData[field].includes(v));
        newUser = newUser.length > 0 ? newUser[0] : null;

        const otherField = field === 'owners' ? 'managers' : 'owners';

        if (newUser !== null) {
            newFormData[otherField] = newFormData[otherField].filter(v => v !== newUser);
        }

        setFormData(newFormData);
    }

    const postDelivery = useMutation(createDelivery, {
        mutationFn: createDelivery,
        onSuccess: () => {
            deliveryRefetch();
        }
    });

    return (
        <>
        <div className="flex flex-col gap-5 pb-5">
            <h2 className="text-lg">Add new MailGun Delivery</h2>
            <Select label="Account" selectedKeys={[ formData.account ]} onChange={e => {
                setFormData({ ...formData, account: e.target.value });
            }}>
                {
                    keys && keys.map((key, index) => {
                        return (
                            <SelectItem key={`${key.key}`} value={key.key}>{key.name}</SelectItem>
                        );
                    })
                }
            </Select>
            <Select label="Day" selectedKeys={[ formData.day ]} onChange={e => {
                setFormData({ ...formData, day: e.target.value });
            }}>
                {
                    days.map((day, idx) => {
                        return (
                            <SelectItem key={idx} value={day}>{day}</SelectItem>
                        );
                    })
                }
            </Select>
            <Select label="Time" selectedKeys={[ formData.time ]} onChange={e => {
                setFormData({ ...formData, time: e.target.value });
            }}>
                {
                    times.map((time, idx) => {
                        return (
                            <SelectItem key={idx} value={time}>{time}</SelectItem>
                        );
                    })
                }
            </Select>
            <Input label="Subject" value={formData.subject} onChange={e => {
                setFormData({ ...formData, subject: e.target.value });
            }}></Input>
            <Textarea label="Message" value={formData.message} onChange={e => {
                setFormData({ ...formData, message: e.target.value})
            }}></Textarea>
            <Textarea label="Email Filters (Comma Separated, Optional)" value={formData.emailFilters} onChange={e => {
                setFormData({ ...formData, emailFilters: e.target.value })
            }}></Textarea>
            <Textarea label="Recipients (Comma Separated)" value={formData.recipients} onChange={e => {
                setFormData({ ...formData, recipients: e.target.value })
            }}></Textarea>
            <Checkbox value={formData.liveOnly} onChange={e => {
                setFormData({ ...formData, liveOnly: e.target.checked });
            }}>Live sites only?</Checkbox>
            <CheckboxGroup label="Add Owners" name="owners" value={formData.owners} onValueChange={e => {handleOwnershipChange('owners', e); }}>
            {
                usersData && usersData.map((user, idx) => {
                    // Exclude the current user
                    if (user._id === currentUser) { return null; }

                    return (
                        <Checkbox key={`user-${idx}`} value={user._id}>{user.firstName} {user.lastName} ({user.email})</Checkbox>
                    )
                })
            }
            </CheckboxGroup>
            <CheckboxGroup label="Add Managers" name="managers" value={formData.managers} onValueChange={e => {handleOwnershipChange('managers', e); }}>
                {
                    usersData && usersData.map((user, idx) => {
                        // Exclude the current user
                        if (user._id === currentUser) { return null; }

                        return (
                            <Checkbox key={`user-${idx}`} value={user._id}>{user.firstName} {user.lastName} ({user.email})</Checkbox>
                        )
                    })
                }
            </CheckboxGroup>   
        </div>
        <Button color="primary" onClick={() => {

            // Check that things aren't empty
            if (formData.account === undefined) { return; }
            if (formData.day === '') { return; }
            if (formData.time === '') { return; }
            if (formData.subject === '') { return; }
            if (formData.message === '') { return; }
            if (formData.recipients === '') { return; }

            postDelivery.mutate({
                ...formData,
                dayOfWeek: days[formData.day],
                user: currentUser
            });

            setFormData({
                account: undefined,
                day: '',
                time: '',
                subject: '',
                message: '',
                liveOnly: false,
                emailFilters: '',
                recipients: '',
                owners: [],
                managers: []
            })

        }}>Add Delivery</Button>
        </>
    );

}

export default MailgunAddNewDelivery;
