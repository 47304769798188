import {getAccounts} from '../endpoints/accounts'
import {useQuery} from '@tanstack/react-query'

import { Card, Spinner } from '@nextui-org/react'

const Accounts = () => {

    const {data,isLoading} = useQuery({queryKey: ['accounts'], queryFn: getAccounts})

    
    let accountList = []
    if(data && !isLoading) {
        accountList = data?.data.map((account, idx) => {
            return (
                <Card key={`account-${idx}`} className="p-2">
                    <div key={account.googleId} className="google-account">
                        <span className="account__image">
                            {account?.image &&
                                <img src={account.image} alt={account.firstName} />
                            }
                        </span>
                        <div className="account__content">
                            <span className="account__name">
                                {account.firstName} {account.lastName}
                            </span>
                            <span className="account__email">
                                {account.email}
                            </span>
                        </div>
                    </div>
                </Card>
            )
        })
    }

    return(
        <>
        { data ? (<div className="flex flex-col gap-4 py-4">{accountList}</div>) : <div className="flex justify-center mx-auto"><Spinner/></div> }
        </>
    )
}

export default Accounts